import * as React from 'react';
import { Button, ButtonGroup, Container, Grid, IconButton, Paper, Table, TableBody, TableContainer, Toolbar, Tooltip, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import FilterListIcon from '@mui/icons-material/FilterList';
import Can from '../../../components/Can';
import { useStores } from '../../../stores';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import { TerritoryGroup } from '../../../domain/TerritoryGroup';
import TerritoryGroupTableRow from './TerritoryGroupTableRow';
import ManageTerritoryGroupDialog from '../dialogs/ManageTerritoryGroupDialog';
import ManageTerritoryDialog from '../dialogs/ManageTerritoryDialog';
import { Territory } from '../../../domain/Territory';
import { LatLng } from 'leaflet';
import AssignTerritoryDialog from '../dialogs/AssignTerritoryDialog';
import TerritoryMap from '../map/TerritoryMap';
import { boundsId } from '../mapUtils';
import FilterTerritoriesModal from './FilterTerritoriesModal';
import TerritoriesTable from './TerritoriesTable';
import { TerritoryAssignment } from '../../../domain/TerritoryAssignment';
import EditAssignmentDialog from '../dialogs/EditAssignmentDialog';

export default observer(() => {
    const { t } = useTranslation();
    const { territoryStore, publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [currentTerritory, setCurrentTerritory] = React.useState<Territory | undefined>();
    const [selectedGroup, setSelectedGroup] = React.useState<TerritoryGroup | undefined>();
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();
    const [selectedAssignment, setSelectedAssignment] = React.useState<TerritoryAssignment | undefined>();

    React.useEffect(() => {
        if (territoryStore.groups.length === 0) {
            territoryStore.getAllGroups();
        }
        if (publisherStore.publishers.length === 0) {
            publisherStore.getAllPublishers();
        }
    }, []);


    const handleCloseDialog = () => {
        closeDialog();
        setSelectedGroup(undefined);
        setSelectedTerritory(undefined);
    }

    const openTerritoryDialog = (territory?: Territory) => {
        if (territory) {
            setSelectedTerritory(territory);
            setCurrentTerritory(territory);
        }
        openDialog("manage-territory")
    }

    const openEditAssignmentDialog = (assignment: TerritoryAssignment) => {
        setSelectedAssignment(assignment);
        openDialog("edit-assign-territory")
    }

    const openAssignTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("assign-territory")
    }

    const openTerritoryGroupDialog = (group: TerritoryGroup) => {
        setSelectedGroup(group);
        openDialog("manage-territory-group")
    }

    const hideTerritoryGroup = (group: TerritoryGroup) => {
        territoryStore.updateTerritoryGroup(group.id, group);
    }

    const handlePolygonClick = (territory: Territory) => {
        openTerritoryDialog(territory);
    }

    const handleFilterChange = (filter: string) => {
        if (filter === "all") {
            territoryStore.getAllGroups();
        } else {
            territoryStore.getAllGroups(filter);
        }
    }

    return (
        <>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={4} >
                        <Paper style={{ height: "80vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                            <Toolbar>
                                <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
                                    {t("territory_groups")}
                                </Typography>
                                <Tooltip title={t("filter")}>
                                    <IconButton onClick={() => openDialog("filter-territories")}>
                                        <FilterListIcon />
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {territoryStore.groups && territoryStore.groups
                                            .map((group: TerritoryGroup) => (
                                                <TerritoryGroupTableRow
                                                    key={group.id}
                                                    group={group}
                                                    editable={true}
                                                    onGroupEdit={openTerritoryGroupDialog}
                                                    onGroupHide={hideTerritoryGroup}
                                                >
                                                    <TerritoriesTable
                                                        territories={group.territories || []}
                                                        onTerritoryFocus={setCurrentTerritory}
                                                        onTerritoryEdit={openTerritoryDialog}
                                                        onTerritoryAssign={openAssignTerritoryDialog}
                                                    />
                                                </TerritoryGroupTableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Can key="create_group_button" do="manage" on='TerritoryGroup'>
                                <ButtonGroup fullWidth>
                                    <Button
                                        variant='contained'
                                        type="button"
                                        onClick={() => openDialog("manage-territory-group")}>
                                        {t("create_territory_group")}
                                    </Button>
                                    <Button
                                        variant='contained'
                                        type="button"
                                        onClick={() => openDialog("manage-territory")}>
                                        {t("create_territory")}
                                    </Button>
                                </ButtonGroup>

                            </Can>
                        </Paper>
                    </Grid>

                    <Grid item xs={8}>
                        <Paper style={{ height: "80vh" }} sx={{ p: 2, display: 'flex', flexDirection: 'column' }} >
                            <TerritoryMap
                                style={{ height: "100%", width: "100%" }}
                                currentTerritory={currentTerritory}
                                onPolygonClick={handlePolygonClick}
                                tooltipOnHover
                                scrollWheelZoom
                                polygons={territoryStore.groups.filter(group => !group.hidden)
                                    .filter(group => !!group.territories)
                                    .map(group => (
                                        group.territories!.map((terr) => {
                                            const bounds = JSON.parse(terr.boundary) as LatLng[][];
                                            return bounds.map(b => ({
                                                id: boundsId(b, terr),
                                                color: group.color,
                                                bounds: b,
                                                title: `${group.name} - ${terr.title}`,
                                                territory: terr
                                            }))
                                        }).flat(1)
                                    )).flat(1)
                                }
                            />
                        </Paper>
                    </Grid>
                </Grid>

            </Container>
            {isOpen("manage-territory-group") && <ManageTerritoryGroupDialog
                group={selectedGroup}
                isOpen={isOpen("manage-territory-group")}
                onClose={handleCloseDialog}
            />}
            {isOpen("manage-territory") && <ManageTerritoryDialog
                territory={selectedTerritory}
                isOpen={isOpen("manage-territory")}
                onAssign={openAssignTerritoryDialog}
                onClose={handleCloseDialog}
            />}
            {isOpen("assign-territory") && <AssignTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("assign-territory")}
                onEdit={openTerritoryDialog}
                onAssignmentEdit={openEditAssignmentDialog}
                onClose={handleCloseDialog}
            />}
            {isOpen("edit-assign-territory") && <EditAssignmentDialog
                assignment={selectedAssignment!}
                isOpen={isOpen("edit-assign-territory")}
                onClose={handleCloseDialog}
            />}
            <FilterTerritoriesModal
                isOpen={isOpen("filter-territories")}
                onClose={handleCloseDialog}
                onFilterChange={handleFilterChange}
            />
        </ >
    );
});