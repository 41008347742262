import { GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { isMobileOnly } from 'react-device-detect';
import { ServiceAssignment } from '../domain/Publisher';

interface HomePage {
    tab: number;
}

interface PublisherPage {
    filter: GridFilterModel;
    sort: GridSortModel;
    page: number;
    current: number;
}

interface GroupReportPage {
    filter: ServiceAssignment | string;
    page: number;
    date: Date;
}

interface SideBarMenu {
    congregation: boolean;
    reports: boolean;
    territories: boolean;
    extra: boolean;
}

export class PanelStore {
    sideBarCollapsed = isMobileOnly;
    homePage = { tab: 0 } as HomePage;
    sideBarMenu = {
        congregation: false,
        reports: false,
        territories: false,
        extra: false
    } as SideBarMenu;
    publishersPage = {} as PublisherPage;
    groupReportPage = {
        filter: "All",
        page: 0,
        date: moment().subtract(1, "month").toDate()
    } as GroupReportPage;

    constructor() {
        makeAutoObservable(this);
    }

    setHomePageTab(tab: number) {
        this.homePage.tab = tab;
    }

    setCollapsed(collapsed: boolean) {
        this.sideBarCollapsed = collapsed;
    }

    setPublishersPagePage = (page: number) => {
        this.publishersPage.page = page;
    }

    setPublishersPageFilter = (filter: GridFilterModel) => {
        this.publishersPage.filter = filter;
    }

    setPublishersPageSort = (sort: GridSortModel) => {
        this.publishersPage.sort = sort;
    }

    setPublishersPageCurrent = (id: number) => {
        this.publishersPage.current = id;
    }

    setGroupReportPageFilter = (filter: string) => {
        this.groupReportPage.filter = filter;
    }

    setGroupReportPagePage = (page: number) => {
        this.groupReportPage.page = page;
    }

    setGroupReportPageDate = (date: Date) => {
        this.groupReportPage.date = date;
    }

    setSideBarMenuOpen = (name: string, open: boolean) => {
        (this.sideBarMenu as any)[name] = open;
    }
}