import * as React from 'react';
import { useFormik } from 'formik';
import { useStores } from '../stores';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from "react-i18next";
import { getLocale } from '../utils/localeUtils';
import { Publisher } from '../domain/Publisher';

interface Props {
    isOpen: boolean;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { reportStore, attendanceStore, publisherStore } = useStores();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: moment().subtract(1, 'month').format('YYYY-MM'),
            activePublishers: 0,
            averageAttendance: 0
        },
        onSubmit: (values) => {
            reportStore.downloadS1Report(values);
            props.onClose();
        }
    });

    React.useEffect(() => {
        if (props.isOpen) {
            attendanceStore.getAverage(formik.values.date, (value) => {
                formik.setFieldValue('averageAttendance', value);
            });

            // shows only current number of active publishers
            if (!publisherStore.publishers.length) {
                publisherStore.getAllPublishers(false, (publishers: Publisher[]) => {
                    const active = publishers.filter(publ => !publ.inactive).length;
                    formik.setFieldValue('activePublishers', active);
                });
            } else {
                const active = publisherStore.publishers.filter(publ => !publ.inactive).length;
                formik.setFieldValue('activePublishers', active);
            }
        }
    }, [props.isOpen, formik.values.date, publisherStore.publishers.length]);

    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("download_s_1")}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={getLocale()}>
                        <DatePicker
                            label={t("date")}
                            views={['year', 'month']}
                            maxDate={new Date()}
                            value={formik.values.date}
                            onChange={value => {
                                formik.setFieldValue('date', value ? moment(value).format('YYYY-MM') : value);
                            }}
                            renderInput={(params) => <TextField {...params} margin="normal" name="date" />}
                        />
                    </LocalizationProvider>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="activePublishers"
                        label={t("active_publishers")}
                        type="number"
                        fullWidth
                        variant="standard"
                        value={formik.values.activePublishers || ''}
                        onChange={formik.handleChange}
                    />
                    <TextField
                        autoFocus
                        margin="normal"
                        id="averageAttendance"
                        label={t("average_attendance")}
                        type="number"
                        fullWidth
                        variant="standard"
                        value={formik.values.averageAttendance || ''}
                        onChange={formik.handleChange}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    <Button type='submit'>{t("submit")}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});