import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../stores';
import { Territory } from '../../../domain/Territory';
import { Publisher } from '../../../domain/Publisher';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isMobileOnly } from 'react-device-detect';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import ReturnTerritoryDialog from '../dialogs/ReturnTerritoryDialog';
import TerritoryAssignmentsTable from './TerritoryAssignmentsTable';
import MobileTerritoryAssignmentsTable from './MobileTerritoryAssignmentsTable';
import { TerritoryAssignment } from '../../../domain/TerritoryAssignment';
import EditAssignmentDialog from '../dialogs/EditAssignmentDialog';


export default observer(() => {
    const { publisherStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [publishers, setPublishers] = React.useState<Publisher[]>([]);
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();
    const [selectedAssignment, setSelectedAssignment] = React.useState<TerritoryAssignment | undefined>();

    React.useEffect(() => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }, []);

    const openReturnTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("return-territory");
    }

    const openEditAssignmentDialog = (assignment: TerritoryAssignment) => {
        setSelectedAssignment(assignment);
        openDialog("edit-assignment")
    }

    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
        setSelectedAssignment(undefined);
    }

    const handleTerritoryReturned = () => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }

    const handleAssignmentChange = () => {
        publisherStore.getPublishersWithTerritoryAssignments((data) => {
            setPublishers(data);
        })
    }


    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {publishers.map(publ => {
                return <Accordion key={publ.id} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography><b>{`${publ.lastName} ${publ.firstName}`}</b> - {publ.territoryAssignments!.length}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {isMobileOnly ? (
                            <MobileTerritoryAssignmentsTable
                                publisher={publ}
                                onTerritoryReturn={openReturnTerritoryDialog}
                            />
                        ) : (
                            <TerritoryAssignmentsTable
                                publisher={publ}
                                onTerritoryReturn={openReturnTerritoryDialog}
                                onAssignmentEdit={openEditAssignmentDialog}
                            />
                        )}

                    </AccordionDetails>
                </Accordion>
            })}
            {isOpen("return-territory") && <ReturnTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("return-territory")}
                onClose={handleCloseDialog}
                onTerritoryReturned={handleTerritoryReturned}
            />}
            {isOpen("edit-assignment") && <EditAssignmentDialog
                assignment={selectedAssignment!}
                isOpen={isOpen("edit-assignment")}
                onClose={handleCloseDialog}
                disableReturnedAt
                onAssignmentChange={handleAssignmentChange}
            />}
        </Container>
    );
});