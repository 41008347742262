import * as React from 'react';
import { useStores } from '../../../stores';
import { useFormik } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Autocomplete, TableContainer, Table, Paper, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Publisher } from '../../../domain/Publisher';
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { Territory } from '../../../domain/Territory';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import { TerritoryAssignment } from '../../../domain/TerritoryAssignment';

interface Props {
    territory: Territory;
    isOpen: boolean;
    onClose: () => void;
    onEdit?: (territory: Territory) => void;
    onAssignmentEdit?: (assignment: TerritoryAssignment) => void;
}

interface FormValues {
    publisher?: Publisher | undefined;
    notes: string;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { territoryStore, publisherStore } = useStores();
    const [isAssigned, setIsAssigned] = React.useState<boolean>(false);
    const [assignments, setAssignments] = React.useState([] as any[]);

    React.useEffect(() => {
        territoryStore.isTerritoryAssigned(props.territory.id, (result) => {
            setIsAssigned(result)
        });
        territoryStore.getAssignments(props.territory.id, (data) => {
            setAssignments(data)
        });
    }, []);

    const currentAssignment = assignments.find(assign => !assign.returnedAt);
    let assignedPublisher;
    if (currentAssignment) {
        assignedPublisher = publisherStore.findLocalPublisher(currentAssignment.publisherId);
    }

    const initialValues = {
        notes: '',
        publisher: assignedPublisher || undefined
    } as FormValues;

    const schema = yup.object().shape({
        notes: yup.string(),
        publisher: yup.object(),
    });
    const formik = useFormik({
        validationSchema: schema,
        enableReinitialize: true,
        initialValues,
        onSubmit: (values, { resetForm }) => {
            territoryStore.assignTerritory(props.territory.id, values.publisher!.id);
            resetForm();
            props.onClose();
        },
    });

    const handleAssignmentEdit = (assignment: TerritoryAssignment) => {
        props.onClose();
        props.onAssignmentEdit && props.onAssignmentEdit(assignment);
    }

    const handleEdit = () => {
        props.onClose();
        props.onEdit && props.onEdit(props.territory);
    }

    const { values } = formik;
    return (
        <Dialog open={props.isOpen} onClose={props.onClose}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                    {t("assign_territory")}
                    {!!props.onEdit && (
                        <IconButton onClick={handleEdit}>
                            <EditIcon />
                        </IconButton>)}
                </DialogTitle>
                <DialogContent>
                    <b>{props.territory.title}</b>

                    {!isAssigned ? <Autocomplete
                        disablePortal={false}
                        id="publisher"
                        options={publisherStore.publishers.filter(publ => !publ.inactive)}
                        disabled={!!currentAssignment}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(publisher: Publisher) => publisher.firstName ? `${publisher.lastName} ${publisher.firstName}` : ''}
                        value={values.publisher || {} as Publisher}
                        renderInput={(params) => <TextField {...params} margin="normal" label={t("publisher")} />}
                        onChange={(e, publisher) => {
                            formik.setFieldValue("publisher", publisher)
                        }}
                    /> : <TextField
                        margin="normal"
                        style={{ minHeight: 50, maxHeight: 150 }}
                        fullWidth
                        disabled={true}
                        type="text"
                        variant="standard"
                        value={t("the_territory_is_assigned_to_someone")}
                        placeholder={t("notes")}
                    />}

                    <TextField
                        margin="normal"
                        id="notes"
                        style={{ minHeight: 50, maxHeight: 150 }}
                        fullWidth
                        type="text"
                        variant="standard"
                        disabled={isAssigned}
                        value={values.notes}
                        placeholder={t("notes")}
                        onChange={formik.handleChange}
                    />

                    {assignments.length > 0 &&
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <caption>{t("this_territory_assignment_history")}</caption>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("publisher")}</TableCell>
                                        <TableCell>{t("taken_at")}</TableCell>
                                        <TableCell>{t("returned_at")}</TableCell>
                                        <TableCell>{t("actions")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {assignments && assignments
                                        .filter(assignment => assignment.returnedAt)
                                        .map((assignment) => {
                                            const publisher = publisherStore.findLocalPublisher(assignment.publisherId)!;
                                            return (
                                                <TableRow key={assignment.id}>
                                                    <TableCell>{publisher ? `${publisher.lastName} ${publisher.firstName}` : t("someone")}</TableCell>
                                                    <TableCell>{moment(assignment.takenAt).format("DD/MM/YY")}</TableCell>
                                                    <TableCell>{moment(assignment.returnedAt).format("DD/MM/YY")}</TableCell>
                                                    <TableCell>
                                                        <IconButton onClick={() => handleAssignmentEdit(assignment)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>{t("cancel")}</Button>
                    {!assignedPublisher && <Button disabled={isAssigned} color="success" type="submit">{t("assign")}</Button>}
                </DialogActions>
            </form>
        </Dialog>
    );
});