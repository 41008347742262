import * as React from 'react';
import { Tab, Tabs } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import Panel from '../../panel/Panel';
import TerritoriesAssignmentTab from './TerritoriesAssignmentTab';
import AllTerritoryRequestsTab from './AllTerritoryRequestsTab';
import TerritoryStatisticsTab from './TerritoryStatisticsTab';
import TerritoryAssignmentHistoryTab from './TerritoryAssignmentHistoryTab';
import { useStores } from '../../../stores';


export default observer(() => {
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(0);
    const { publisherStore } = useStores();

    React.useEffect(() => {
        if (!publisherStore.publishers.length) {
            publisherStore.getAllPublishers();
        }
    }, [publisherStore.publishers.length]);

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const tabMenu = [
        <Tab key="assignments" label={t("appointments")} />,
        <Tab key="requests" label={t("requests")} />,
        <Tab key="history" label={t("history")} />,
        <Tab key="statistics" label={t("statistics")} />
    ];

    return (
        <Panel title={t("appointments")} headerItems={[]}>
            <Tabs value={tab} onChange={handleChange} variant="scrollable" scrollButtons="auto">
                {tabMenu}
            </Tabs>
            <div role="tabpanel" hidden={tab !== 0}>
                <TerritoriesAssignmentTab />
            </div>
            <div role="tabpanel" hidden={tab !== 1}>
                <AllTerritoryRequestsTab />
            </div>
            <div role="tabpanel" hidden={tab !== 2}>
                <TerritoryAssignmentHistoryTab />
            </div>
            <div role="tabpanel" hidden={tab !== 3}>
                <TerritoryStatisticsTab />
            </div>
        </Panel >
    );
});