import * as React from 'react';
import { useStores } from '../../../stores';
import { TableContainer, Table, TableBody, TableRow, TableCell, TableHead, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { ScheduleEntry } from '../../../domain/ScheduleEntry';

interface Props {
    isOpen: boolean;
    publisherId?: number;
    onClose: () => void;
}

export default observer((props: Props) => {
    const { t } = useTranslation();
    const { scheduleStore } = useStores();
    const [entries, setEntries] = React.useState<ScheduleEntry[]>([]);

    React.useEffect(() => {
        if (props.isOpen) {
            scheduleStore.getEntriesByPublisher(props.publisherId!, (e: ScheduleEntry[]) => {
                setEntries(e.filter(entry => entry.subType !== "assignment"));
            });
        }
    }, [props.publisherId, props.isOpen]);

    const getRole = (entry: ScheduleEntry) => {
        if (entry.speakerId === props.publisherId) {
            return t("speaker");
        } else if (entry.companionId === props.publisherId) {
            return t("companion");
        } else if (entry.impromptuId === props.publisherId) {
            return t("impromptu");
        } else return "";
    }

    return (
        <Dialog maxWidth="lg" open={props.isOpen} onClose={props.onClose}>
            <DialogTitle>{t("schedule")}</DialogTitle>
            <DialogContent>
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("date")}</TableCell>
                                <TableCell>{t("name")}</TableCell>
                                <TableCell>{t("room")}</TableCell>
                                <TableCell>{t("role")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {entries && entries
                                // .sort((entry1, entry2) => {
                                //     if (entry1.week && entry2.week) {
                                //         return new Date(entry2.week!.date).getTime() - new Date(entry1.week!.date).getTime()
                                //     }
                                //     return 0;
                                // })
                                .map((entry: ScheduleEntry) => (
                                    <TableRow key={entry.id}>
                                        <TableCell>{moment(entry.week!.date).format("DD-MM-YYYY")}</TableCell>
                                        <TableCell>{entry.name}</TableCell>
                                        <TableCell>{entry.room}</TableCell>
                                        <TableCell>{getRole(entry)}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    );
});