import * as React from 'react';
import Container from '@mui/material/Container';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTranslation } from "react-i18next";
import CartWitnessingContent from '../carts/CartWitnessingContent';
import { HomeTabs } from './HomeTabs';
import { Badge } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import FieldServiceReportDialog from '../publishers/dialogs/FieldServiceReportDialog';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import ScheduleContent from '../schedule/ScheduleContent';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import MyTerritoriesContent from '../territories/my/MyTerritoriesContent';
import moment from 'moment';

export default observer(() => {
    const { panelStore, userStore, publisherStore, reportStore, alertStore } = useStores();
    const { t } = useTranslation();
    const [tab, setTab] = React.useState(panelStore.homePage.tab);
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const navigate = useNavigate();
    const user = userStore.userData;
    const hasPublisher = !!user.publisherId;

    if (hasPublisher) {
        React.useEffect(() => {
            reportStore.shouldPublisherSubmitReport(user.publisherId!, moment().subtract(1, 'month').format("YYYY-MM-DD"));
        }, [reportStore.serviceReports[user.publisherId!]]);
    }

    const handleTabChange = (tab: number) => {
        setTab(tab);
        panelStore.setHomePageTab(tab);
    }

    const handleReportSubmit = (report: FieldServiceReport) => {
        reportStore.createS4Report(report, () => {
            closeDialog();
        });
    }

    const navigateToNotifications = () => {
        navigate('/alerts');
    }

    const navigateToEvents = () => {
        navigate('/events');
    }

    const headerItems = [];
    if (hasPublisher) {
        const number = alertStore.alerts.length;
        headerItems.push(
            <CalendarMonthIcon key="events_btn" style={{ marginRight: "5px" }} onClick={navigateToEvents} />,
            <Badge key="notification_btn" badgeContent={number} color="error">
                <NotificationsNoneIcon onClick={navigateToNotifications} />
            </Badge>
        );
    }

    return (
        <Panel title={t("home")} headerItems={headerItems}>
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <HomeTabs
                    selected={tab}
                    onClick={handleTabChange}
                    alreadySubmittedReport={reportStore.submitted}
                    onSubmitReportClick={() => openDialog("submit-report")}
                />

                <div role="tabpanel" hidden={tab !== 0}>
                    <CartWitnessingContent />
                </div>
                <div role="tabpanel" hidden={tab !== 1}>
                    <MyTerritoriesContent size="xl" />
                </div>
                <div role="tabpanel" hidden={tab !== 2}>
                    <ScheduleContent editable={false} />
                </div>
            </Container>
            <FieldServiceReportDialog
                isOpen={isOpen("submit-report")}
                publisher={publisherStore.myPublisher!}
                onSubmit={handleReportSubmit}
                onClose={closeDialog} />
        </Panel>
    );
})