import * as React from 'react';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Panel from '../panel/Panel';
import { useStores } from '../../stores';
import { Button, LinearProgress, Menu, MenuItem } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FieldServiceReport } from '../../domain/FieldServiceReport';
import { useTranslation } from "react-i18next";
import GroupReportsTable from './GroupReportsTable';
import GenerateS1Dialog from '../../components/GenerateS1Dialog';
import Can from '../../components/Can';
import { Publisher, ServiceAssignment } from '../../domain/Publisher';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { useOpenDialog } from '../../hooks/useOpenDialog';
import PivotRangeExcelDialog from '../pivot-reports/PivotRangeExcelDialog';

export default observer(() => {
    const { t } = useTranslation();
    const { publisherStore, reportStore, panelStore, serverStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [date, setDate] = React.useState<Date>(panelStore.groupReportPage.date);
    const [assignment, setAssignment] = React.useState<ServiceAssignment | string>(panelStore.groupReportPage.filter);
    const [publishers, setPublishers] = React.useState<Publisher[]>([]);
    const navigate = useNavigate();
    const [anchorExcelMenuBtn, setAnchorExcelMenuBtn] = React.useState<null | HTMLElement>(null);
    const openExcelMenu = Boolean(anchorExcelMenuBtn);

    const handleExcelClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorExcelMenuBtn(event.currentTarget);
    };

    const handleExcelMenuClose = () => {
        setAnchorExcelMenuBtn(null);
    };

    React.useEffect(() => {
        publisherStore.getPublishersWithReports(moment(date).format("YYYY-MM"), (data) => {
            setPublishers(data);
        });
    }, [date]);

    const openPivotReports = () => {
        navigate('/pivot-reports');
    }

    const downloadAssignmentExcel = () => {
        reportStore.downloadAssignmentExcel(assignment, date);
        handleExcelMenuClose();
    }

    const downloadRangeExcel = () => {
        openDialog("range-excel-dialog")
        handleExcelMenuClose();
    }

    const handleDateChange = (date: Date) => {
        setDate(date);
    }

    const handleAssignmentChange = (assignment: ServiceAssignment | string) => {
        setAssignment(assignment);
    }

    const handleSubmit = (report: FieldServiceReport) => {
        reportStore.saveS4Report(report, () => {
            publisherStore.getAllPublishers(true, (data) => {
                setPublishers(data);
            });
        });
    }

    const headerItems = () => {
        const items = [];
        if (!isMobile) {
            items.push(
                <Can key="btn_download_s1" do="create" on='S1Report'>
                    <Button
                        type="button"
                        color="inherit"
                        onClick={() => openDialog("s1-dialog")}>
                        {t("download_s_1")}
                    </Button>
                </Can>
            );

            items.push(
                <>
                    <Button
                        key="excel_btn"
                        type="button"
                        color="inherit"
                        onClick={handleExcelClick}
                    >
                        {t("download_excel")}
                    </Button>
                    <Menu
                        key="download-excel-btn"
                        anchorEl={anchorExcelMenuBtn}
                        open={openExcelMenu}
                        onClose={handleExcelMenuClose}
                    >
                        <MenuItem onClick={downloadAssignmentExcel}>{t("download_assignment_excel")}</MenuItem>
                        <MenuItem onClick={downloadRangeExcel}>{t("download_range_excel")}</MenuItem>
                    </Menu>
                </>

            )
        }

        items.push(
            <Button
                key="pivot_reports_btn"
                type="button"
                color="inherit"
                onClick={openPivotReports}>
                {t("pivot_reports")}
            </Button>
        );
        return items;
    }

    return (
        <Panel
            title={t("overall_report")}
            headerItems={headerItems()}
        >
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ height: 1000, width: '100%' }} >
                        {serverStore.serverRequestLoading && <LinearProgress />}
                        <GroupReportsTable
                            publishers={publishers}
                            onSubmit={handleSubmit}
                            onDateChange={handleDateChange}
                            onAssignmentChange={handleAssignmentChange}
                        />
                    </div>
                </Paper>
            </Container>
            {isOpen("s1-dialog") && <GenerateS1Dialog
                isOpen={isOpen("s1-dialog")}
                onClose={closeDialog} />}
            {isOpen("range-excel-dialog") && <PivotRangeExcelDialog
                isOpen={isOpen("range-excel-dialog")}
                onClose={closeDialog}
            />}
        </Panel >
    );
});