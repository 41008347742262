import * as React from 'react';
import { Container, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from "react-i18next";
import { useStores } from '../../../stores';
import moment from 'moment';
import { TerritoryAssignment } from '../../../domain/TerritoryAssignment';
import { Territory } from '../../../domain/Territory';
import { useOpenDialog } from '../../../hooks/useOpenDialog';
import ManageTerritoryDialog from '../dialogs/ManageTerritoryDialog';
import AssignTerritoryDialog from '../dialogs/AssignTerritoryDialog';

const PAGE_SIZE = 10;

export default observer(() => {
    const { t } = useTranslation();
    const { territoryStore } = useStores();
    const { isOpen, openDialog, closeDialog } = useOpenDialog();
    const [page, setPage] = React.useState<number>(1);
    const [history, setHistory] = React.useState<TerritoryAssignment[]>([]);
    const [selectedTerritory, setSelectedTerritory] = React.useState<Territory | undefined>();

    React.useEffect(() => {
        territoryStore.getHistory(page, PAGE_SIZE, (data) => {
            setHistory(data);
        })
    }, [page]);

    const handleChangePage = (_: any, newPage: number) => {
        setPage(newPage+1);
    };

    const openTerritoryDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("manage-territory");
    }

    const openAssignmentDialog = (territory: Territory) => {
        setSelectedTerritory(territory);
        openDialog("assign-territory");
    }

    const handleCloseDialog = () => {
        closeDialog();
        setSelectedTerritory(undefined);
    }

    return (
        <>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("title")}</TableCell>
                                <TableCell>{t("group")}</TableCell>
                                <TableCell>{t("publisher")}</TableCell>
                                <TableCell>{t("taken_at")}</TableCell>
                                <TableCell>{t("returned_at")}</TableCell>
                                <TableCell>{t("actions")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history.map(assignment => (
                                <TableRow key={assignment.id}>
                                    <TableCell>{assignment.territory!.title}</TableCell>
                                    <TableCell>{assignment.territory!.territoryGroup!.name}</TableCell>
                                    <TableCell>{assignment.publisher ? `${assignment.publisher!.lastName} ${assignment.publisher!.firstName}` : t("someone")}</TableCell>
                                    <TableCell>{moment(assignment.takenAt).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>{moment(assignment.returnedAt).format("DD/MM/YYYY")}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => openTerritoryDialog(assignment.territory!)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => openAssignmentDialog(assignment.territory!)}>
                                            <AssignmentIndIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={-1}
                    rowsPerPageOptions={[]}
                    rowsPerPage={PAGE_SIZE}
                    page={page - 1}
                    onPageChange={handleChangePage}
                />
            </Container>
            {isOpen("manage-territory") && <ManageTerritoryDialog
                territory={selectedTerritory}
                isOpen={isOpen("manage-territory")}
                onClose={handleCloseDialog}
            />}
            {isOpen("assign-territory") && <AssignTerritoryDialog
                territory={selectedTerritory!}
                isOpen={isOpen("assign-territory")}
                onClose={handleCloseDialog}
            />}
        </>

    );
});